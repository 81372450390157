import config from '../config'

const getPostData = data => ({
	headers: {
		'Content-Type': 'application/json',
		'X-Tyto-Source': 'www',
	},
	method: 'POST',
	body: JSON.stringify(data),
})

const handleResponse = async r => {
	const stream = r.json()
	if (r.ok) {
		return stream
	}
	throw await stream
}

const getRequest = url => fetch(`${config.API_URL}${url}`).then(handleResponse)

const postRequest = (url, data) =>
	fetch(`${config.API_URL}${url}`, getPostData(data)).then(handleResponse)

export default {
	getRequest,
	postRequest,
	getSignupTokenData: token => getRequest(`/tokens/data?token=${token}`),
	confirmAccountDeletion: data =>
		postRequest('/actions/confirm-account-deletion', data),
	requestAccountDeletion: data =>
		postRequest('/actions/request-account-deletion', data),
	signUp: data => postRequest(`/auth/sign-up`, data),
	subscribeToMailList: email =>
		postRequest(`/mail-list/subscribe`, { email }),
	switchTeam: (token, action) =>
		postRequest(`/emails/switchTeam`, getPostData({ token, action })),
}
