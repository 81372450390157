import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Container, Typography } from '@material-ui/core'

import { useAsync } from '../../utils/hooks'
import Layout from '../../components/Layout'
import api from '../../utils/api'
import useSearchParams from '../../utils/useSearchParams'

const useStyles = makeStyles(
	theme => ({
		root: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			paddingBottom: theme.spacing(8),
			paddingTop: theme.spacing(8),
		},
	}),
	{ name: 'SwitchTeam' }
)

const SwitchTeam = () => {
	const { action, resetSearchParams, token } = useSearchParams()
	const { run, status } = useAsync()
	const classes = useStyles()

	useEffect(() => {
		if (token && action) {
			run(api.switchTeam(token, action))
			resetSearchParams()
		}
	}, [action, resetSearchParams, run, token])

	const message =
		action === 'accept'
			? 'Your team was switched successfully.'
			: 'Invite declined. You will stay with your current team.'

	return (
		<Layout>
			<Container className={classes.root}>
				<Typography align={'center'} variant={'h4'}>
					{status === 'pending' || status === 'idle' ? (
						<CircularProgress />
					) : status === 'rejected' ? (
						'Error switching teams'
					) : (
						message
					)}
				</Typography>
			</Container>
		</Layout>
	)
}

export default SwitchTeam
