import { useCallback, useEffect, useState } from 'react'

const useSearchParams = () => {
	const [data, setData] = useState({})

	useEffect(() => {
		const searchParams = localStorage.getItem('ty-www.searchParams')
		setData(JSON.parse(searchParams))
	}, [])

	const resetSearchParams = useCallback(() => {
		localStorage.removeItem('ty-www.searchParams')
	}, [])

	return { ...data, resetSearchParams }
}

export default useSearchParams
